import * as React from 'react';
import styled from 'styled-components';

import { BaseTable, TableCell } from '../common';
import { ColumnData, RecordHistoryTableColumn, ColumnSort } from '../../models';
import { useAppState } from '../../context';
import moment from 'moment';
import { ActionHistory } from '../../models/ActionHistory';

const Wrapper = styled.div`
    display: grid;
    grid-row-gap: 18px;
    margin-top: 40px;
    padding: 0 40px;
`;

const Label = styled.div`
    color: var(--eerie-black);
    font-family: var(--open-sans-semibold-font);
    font-size: 20px;
`;

const NoDataNote = styled.div`
    font-style: italic;
`;

const COLUMN_DATA: ColumnData<RecordHistoryTableColumn>[] = [
    { label: 'Action', type: RecordHistoryTableColumn.ACTION, sortable: false, propName: 'action' },
    { label: 'Detail', type: RecordHistoryTableColumn.DETAIL, sortable: false, propName: 'detail' },
    { label: 'Name', type: RecordHistoryTableColumn.NAME, sortable: false, propName: 'name' },
    { label: 'Date', type: RecordHistoryTableColumn.DATE, sortable: false, propName: 'date' },
];

let TABLE_DATA: ActionHistory[] = [];

const RecordHistory: React.FC = () => {
    const {
        recordDetailPage: {
            recordDetail: { History },
        },
    } = useAppState();

    // This may change in the future to support history records of retries and discards.
    TABLE_DATA = History.map((h) => {
        return {
            action: 'MANUAL EDIT',
            detail: `Changed: ${h.field} from "${h.oldValue}" to "${h.newValue}"`,
            name: h.user,
            date: moment(h.dateOfChange).format('MM/DD/YY hh:mm:ss a'),
        };
    });

    // No sorting on this table
    const toggleSort = () => {
        /* intentionally blank */
    };

    return (
        <Wrapper>
            <Label>Record History</Label>
            <BaseTable
                columnData={COLUMN_DATA}
                sortBy={RecordHistoryTableColumn.DATE}
                orderBy={ColumnSort.DESC}
                tableData={TABLE_DATA}
                toggleSort={toggleSort}
                gridTemplateColumns="1fr 50% 1fr 15.5%"
                renderRow={(item: ActionHistory, idx: number) => {
                    return (
                        <React.Fragment key={idx}>
                            <TableCell index={idx}>{item.action}</TableCell>
                            <TableCell index={idx}>{item.detail}</TableCell>
                            <TableCell index={idx}>{item.name}</TableCell>
                            <TableCell index={idx}>{item.date}</TableCell>
                        </React.Fragment>
                    );
                }}
                renderNoData={() => {
                    return (
                        <>
                            <TableCell index={0}>
                                <NoDataNote>No record history available at this time.</NoDataNote>
                            </TableCell>
                            <TableCell index={0} />
                            <TableCell index={0} />
                            <TableCell index={0} />
                        </>
                    );
                }}
            />
        </Wrapper>
    );
};

export default RecordHistory;
