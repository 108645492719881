import { SearchResultsTableColumn } from '../../models';
import { SearchResponse } from '../../models/response/SearchResponse';

export enum SearchPageActionType {
    SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS',
    SEARCH_RESULTS_SORT = 'SEARCH_RESULTS_SORT',
}

export type SearchPageAction =
    | {
          type: SearchPageActionType.SET_SEARCH_RESULTS;
          searchResults: SearchResponse;
      }
    | {
          type: SearchPageActionType.SEARCH_RESULTS_SORT;
          sortBy: SearchResultsTableColumn;
          propName: string;
      };
