import { SearchPageState as State } from './State';
import { SearchPageAction as Action, SearchPageActionType as ActionType } from './Action';
import { ColumnSort } from '../../models';

const checkExhaustive = (_: never): void => {};

export const searchPageReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.SET_SEARCH_RESULTS: {
            return {
                ...state,
                ...action.searchResults,
                searchResults: [...action.searchResults.items],
            };
        }
        case ActionType.SEARCH_RESULTS_SORT: {
            const newOrderBy = state.sorting.sortBy !== action.sortBy ? ColumnSort.ASC : state.sorting.orderBy === ColumnSort.ASC ? ColumnSort.DESC : ColumnSort.ASC;
            return {
                ...state,
                sorting: {
                    ...state.sorting,
                    sortBy: action.sortBy,
                    orderBy: newOrderBy,
                    propName: action.propName,
                },
            };
        }
        default:
            checkExhaustive(action);
            return state;
    }
};
