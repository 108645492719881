import * as React from 'react';
import { Link } from 'react-router-dom';

import { useAppState, useAppDispatch, sortRecordErrors, enableSpinner, disableSpinner, showErrorModal, removeErrorRecord, showDiscardModal, toggleConfirmDiscardAll, toggleCanceledDiscardAll, setFiltersAreApplied } from '../../context';
import { Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel } from '@material-ui/core';
import { ColumnData, ColumnSort, ModalType, RecordErrorsTableColumn, RecordStatus } from '../../models';
import { useSortedRecordErrors } from './Hooks';
import DownloadButton from './DownloadButton';
import RetryButton from './RetryButton';
import DiscardButton from '../record-detail/DiscardButton';
import { SearchResultDoc } from '../../models/response';
import styled from 'styled-components';
import { RecordsSvc } from '../../services';
import { useRetryToast } from '../common/Hooks';
import { Checkbox } from '../common';

const COLUMN_DATA: ColumnData<RecordErrorsTableColumn>[] = [
    { label: 'ID', type: RecordErrorsTableColumn.ID, sortable: true, propName: 'id' },
    {
        label: 'Type',
        type: RecordErrorsTableColumn.RECORD_TYPE,
        sortable: true,
        propName: 'recordType',
    },
    {
        label: 'Step',
        type: RecordErrorsTableColumn.STEP,
        sortable: true,
        propName: 'nextStep',
    },
    {
        label: 'Category',
        type: RecordErrorsTableColumn.CATEGORY,
        sortable: true,
        propName: 'errorCategory',
    },
    {
        label: 'Service',
        type: RecordErrorsTableColumn.SERVICE,
        sortable: true,
        propName: 'errorService',
    },
    {
        label: 'Error Name',
        type: RecordErrorsTableColumn.NAME,
        sortable: true,
        propName: 'errorName',
    },
    {
        label: 'Error Message',
        type: RecordErrorsTableColumn.MESSAGE,
        sortable: true,
        propName: 'errorMessage',
    },
];

const ButtonsLayout = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-gap: 10px;
    justify-content: start;
`;

const ErrorsTab: React.FC = () => {
    const dispatch = useAppDispatch();
    const {
        dashboardPage: { sorting },
        globalFilters: { filtersAreApplied },
        modal: { confirmedAll, cancelledAll, type },
    } = useAppState();
    const { sortedRecords } = useSortedRecordErrors();
    const { retryToast } = useRetryToast();

    const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([]);
    const [checked, setChecked] = React.useState(false);
    const [isRetryDisabled, setRetryDisabled] = React.useState(true);
    const [isDiscardDisabled, setDiscardDisabled] = React.useState(true);

    const [sortBy, setSortBy] = React.useState(null);
    const [sortConfig, setSortConfig] = React.useState({ key: null, direction: 'asc' });

    React.useEffect(() => {
        if (cancelledAll) {
            dispatch(toggleCanceledDiscardAll());
            setSelectedCheckboxes([]);
            return;
        } else if (!confirmedAll || type !== ModalType.DISCARD) {
            return;
        }
        dispatch(enableSpinner());
        selectedCheckboxes.forEach(async (record, index) => {
            const recordIndex = selectedCheckboxes[index];
            const result = await RecordsSvc.putDiscard(sortedRecords[recordIndex].documentId, sortedRecords[recordIndex].recordType, RecordStatus.Error).catch((e) => console.error('Error trying to discard record', e));
            if (result) {
                dispatch(removeErrorRecord(sortedRecords[recordIndex].documentId));
            } else {
                dispatch(showErrorModal());
            }
        });
        dispatch(disableSpinner());
        setSelectedCheckboxes([]);
        // Reset confirm state
        dispatch(toggleConfirmDiscardAll());
        // eslint-disable-next-line
    }, [confirmedAll, cancelledAll, dispatch]);

    React.useEffect(() => {
        if (selectedCheckboxes.length === 0) {
            setRetryDisabled(true);
            setDiscardDisabled(true);
            setChecked(false);
        } else if (selectedCheckboxes.length > 0 && selectedCheckboxes.length < sortedRecords.length) {
            setRetryDisabled(false);
            setDiscardDisabled(false);
            setChecked(false);
        } else if (selectedCheckboxes.length === sortedRecords.length) {
            setRetryDisabled(false);
            setDiscardDisabled(false);
            setChecked(true);
        }
    }, [selectedCheckboxes, sortedRecords]);

    React.useEffect(() => {
        if (filtersAreApplied && sortedRecords.length > 0) {
            setRetryDisabled(true);
            setDiscardDisabled(true);
            setSelectedCheckboxes([]);
            setChecked(false);
        }
        dispatch(setFiltersAreApplied(false));
        // eslint-disable-next-line
    }, [filtersAreApplied, dispatch]);

    const handleSort = (column, propName) => {
        setSortBy(column);
        setSortConfig({ key: column, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
        sorting.sortBy = column;
        if (sortConfig.direction === 'asc') {
            sorting.orderBy = ColumnSort.ASC;
        } else if (sortConfig.direction === 'desc') {
            sorting.orderBy = ColumnSort.DESC;
        } else {
            sorting.orderBy = ColumnSort.ASC;
        }
        dispatch(sortRecordErrors(column, propName));
    };

    const onRetry = async () => {
        const records = selectedCheckboxes.length;
        dispatch(enableSpinner());
        selectedCheckboxes.forEach(async (record, index) => {
            const recordIndex = selectedCheckboxes[index];
            const result = await RecordsSvc.putRetry(sortedRecords[recordIndex].documentId, sortedRecords[recordIndex].recordType, RecordStatus.Error).catch((e) => console.error('Error trying to retry record', e));
            if (result) {
                dispatch(removeErrorRecord(sortedRecords[recordIndex].documentId));
            } else {
                dispatch(showErrorModal());
            }
        });
        retryToast(records);
        dispatch(disableSpinner());
        setSelectedCheckboxes([]);
    };

    const onDiscard = () => {
        dispatch(showDiscardModal('', '', RecordStatus.Error, selectedCheckboxes.length));
    };

    const handleCheckboxChange = (checkboxId) => {
        if (selectedCheckboxes.includes(checkboxId)) {
            setSelectedCheckboxes(selectedCheckboxes.filter((id) => id !== checkboxId));
        } else {
            setSelectedCheckboxes([...selectedCheckboxes, checkboxId]);
        }
    };

    const toggleSelectAll = () => {
        if (selectedCheckboxes.length > 0 && selectedCheckboxes.length === sortedRecords.length) {
            setSelectedCheckboxes([]);
            setChecked(false);
        } else {
            setSelectedCheckboxes(sortedRecords.map((record, index) => index));
            setChecked(true);
        }
    };

    const direction = (columnType) => {
        if (sortConfig.key === columnType && sortConfig.direction === 'asc') {
            return 'asc';
        } else if (sortConfig.key === columnType && sortConfig.direction === 'desc') {
            return 'desc';
        } else {
            return 'asc';
        }
    };

    return (
        <div>
            <ButtonsLayout>
                <DownloadButton data={sortedRecords} filename="errors" />
                <RetryButton disabled={isRetryDisabled} isRetryAll={true} onClick={onRetry} />
                <DiscardButton disabled={isDiscardDisabled} isDiscardAll={true} onClick={() => onDiscard()} />
            </ButtonsLayout>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Checkbox showBorder={true} isChecked={checked} onClick={() => toggleSelectAll()} />
                        </TableCell>
                        {COLUMN_DATA.map((column) => (
                            <TableCell variant="head" key={column.type}>
                                <TableSortLabel active={sortBy === column.type} direction={direction(column.type)} onClick={() => handleSort(column.type, column.propName)}>
                                    {column.label}
                                </TableSortLabel>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {sortedRecords.length === 0 ? (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={8} align="center">
                                There are no record errors for the current filters.
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ) : (
                    <TableBody>
                        {sortedRecords.map((record: SearchResultDoc, idx: number) => (
                            <TableRow key={idx}>
                                <TableCell>
                                    <Checkbox showBorder={true} key={idx} isChecked={selectedCheckboxes.includes(idx)} onClick={() => handleCheckboxChange(idx)} />
                                </TableCell>
                                <TableCell>
                                    <Link to={`/records/${record.documentId}`}>{record.documentId}</Link>
                                </TableCell>
                                <TableCell>{record.recordType}</TableCell>
                                <TableCell>{record.nextStep}</TableCell>
                                <TableCell>{record.errorCategory}</TableCell>
                                <TableCell>{record.errorService}</TableCell>
                                <TableCell>{record.errorName}</TableCell>
                                <TableCell>{record.errorMessage}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                )}
            </Table>
        </div>
    );
};

export default ErrorsTab;
