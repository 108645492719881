import { ColumnSort, SearchResultsTableColumn } from '../../models';
import { SearchResultDoc } from '../../models/response';

export interface SearchPageState {
    searchResults: SearchResultDoc[];
    sorting: {
        sortBy: SearchResultsTableColumn;
        orderBy: ColumnSort;
        propName: string;
    };
    maxItemCount: number;
    maxLimitReached: boolean;
    totalItemCount: number;
}

export const initialState: SearchPageState = {
    searchResults: [],
    sorting: {
        sortBy: SearchResultsTableColumn.ID,
        orderBy: ColumnSort.ASC,
        propName: 'documentId',
    },
    maxItemCount: 0,
    maxLimitReached: false,
    totalItemCount: 0,
};
