import { DashboardTabs, LiveStat, RecordErrorsTableColumn } from '../../models';
import { SearchResultDoc } from '../../models/response';

export enum DashboardPageActionType {
    SELECT_DB_TAB = 'SELECT_DB_TAB',
    RECORD_ERRORS_SORT = 'RECORD_ERRORS_SORT',
    TOGGLE_FLYOUT_OPEN = 'TOGGLE_FLYOUT_OPEN',
    SET_ERROR_RECORDS = 'SET_ERROR_RECORDS',
    SET_TOTALS = 'SET_TOTALS',
    SET_AVERAGES = 'SET_AVERAGES',
    SET_LIVE_STATS = 'SET_LIVE_STATS',
    REMOVE_ERROR = 'REMOVE_ERROR',
}

export type DashboardPageAction =
    | { type: DashboardPageActionType.SELECT_DB_TAB; tab: DashboardTabs }
    | {
          type: DashboardPageActionType.RECORD_ERRORS_SORT;
          sortBy: RecordErrorsTableColumn;
          propName: string;
      }
    | { type: DashboardPageActionType.TOGGLE_FLYOUT_OPEN }
    | {
          type: DashboardPageActionType.SET_ERROR_RECORDS;
          records: SearchResultDoc[];
      }
    | {
          type: DashboardPageActionType.SET_TOTALS;
          ingested: number;
          succeeded: number;
          errored: number;
      }
    | {
          type: DashboardPageActionType.SET_AVERAGES;
          currentIngested: number;
          currentSucceeded: number;
          currentErrored: number;
          previousIngested: number;
          previousSucceeded: number;
          previousErrored: number;
          displayPerformance: boolean;
      }
    | {
          type: DashboardPageActionType.SET_LIVE_STATS;
          liveStats: LiveStat[];
      }
    | {
          type: DashboardPageActionType.REMOVE_ERROR;
          recordId: string;
      };
