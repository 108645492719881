import * as R from 'ramda';

import { useAppState, useAppDispatch, setSearchResults, disableSpinner, enableSpinner, showErrorModal } from '../../context';
import { ColumnSort, FilterQueryParams } from '../../models';
import { SearchResultDoc } from '../../models/response';
import { RecordsSvc } from '../../services';

const buildSortDirection = (orderBy: ColumnSort) => {
    return orderBy === ColumnSort.DESC ? R.descend : R.ascend;
};

export const useSortedSearchResults = (): {
    sortedSearchResults: SearchResultDoc[];
} => {
    const {
        searchPage: { searchResults, sorting },
    } = useAppState();

    let sortedSearchResults = [...searchResults];
    if (sortedSearchResults.length <= 1 || sorting.orderBy === ColumnSort.NONE) {
        return { sortedSearchResults };
    }

    const sortDirection = buildSortDirection(sorting.orderBy);
    const sortFunc = (r: SearchResultDoc): string => R.prop(sorting.propName as any, r);
    sortedSearchResults = R.sort(sortDirection(sortFunc), sortedSearchResults);

    return { sortedSearchResults };
};

export const useFetchSearchResults = () => {
    const dispatch = useAppDispatch();
    const { globalFilters } = useAppState();

    const fetchSearchResults = (searchFor: string) => {
        const fetchSearchResultsAsync = async () => {
            dispatch(enableSpinner());
            const params: FilterQueryParams = {
                start: globalFilters.currentFilters.dateRange.start,
                end: globalFilters.currentFilters.dateRange.end,
                recordTypes: globalFilters.currentFilters.recordTypes,
                statuses: globalFilters.currentFilters.recordStatus,
                dateFilterMode: globalFilters.currentFilters.dateFilterMode,
            };
            const data = await RecordsSvc.getSearchRecords(params, searchFor).catch((e) => {
                console.error('Error fetching search results', e);
                dispatch(showErrorModal());
            });
            if (data) {
                dispatch(setSearchResults(data));
            }
            dispatch(disableSpinner());
        };

        fetchSearchResultsAsync();
    };

    return {
        fetchSearchResults,
    };
};
